<template>
  <div class="property-wrapper">
    <template v-if="controls.length == 1">
      <ControlBaseProperties v-model="control" />
      <DetailFormTable
        title="specific_properties"
        :headerFields="false"
        :datasetFields="false"
      />
    </template>
  </div>
</template>

<script>
import ControlBaseProperties from "./control-base-properties";
import BaseControl from "./base-control.vue";
import DetailFormTable from "@/components/control-sidebar/property-editors/detail-form-table.vue";

export default {
  name: "SimpleTable",
  components: {
    ControlBaseProperties,
    DetailFormTable
  },
  extends: BaseControl,
  data() {
    return {};
  },
  computed: {
    control: {
      set(val) {
        this.$store.dispatch("synoptic/updateControl", {
          id: val.id,
          control: val,
          noMerge: true
        });
      },
      get() {
        let lst = this?.controls || [];
        return lst.length ? lst[0] : null;
      }
    }
  },
  methods: {
    onTableEvent($event) {
      switch ($event.action) {
        case "sheet:client_rect": {
          let control = this.control;
          let rect = control?.synopticComponent?.clientRect || {};

          rect.width = Math.round($event.details.width);
          rect.height = Math.round($event.details.height);
          this.$set(this, "control", control);

          break;
        }
      }
    }
  },
  created() {
    this.$root.$on("table:event", this.onTableEvent);
  },
  beforeDestroy() {
    this.$root.$off("table:event", this.onTableEvent);
  }
};
</script>

<style scoped></style>
